.Button_button__A6Olm {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    border-width: 1px;
    font-weight: 600;
}

  .Button_button__A6Olm svg {
    margin-left: 1rem;
}

  .Button_button__A6Olm.Button_iconLeft__JOdU1 {
    flex-direction: row-reverse;
}

  .Button_button__A6Olm.Button_iconLeft__JOdU1 svg {
    margin-right: 3rem;
    margin-left: 0px;
}

  .Button_button__A6Olm:hover, .Button_button__A6Olm:focus, .Button_button__A6Olm:active:not([disabled]) {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}

  /* SIZES */

  .Button_button__A6Olm.Button_lg__LRBZS {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
}

  .Button_button__A6Olm.Button_md__L1Nu9 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

  .Button_button__A6Olm.Button_sm__l1eZ6 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

  .Button_button__A6Olm.Button_fluid__iy6Sr {
    display: block;
    width: 100%;
    text-align: center;
}

  /* TYPES */

  .Button_button__A6Olm.Button_primary__TKc7J {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

  .Button_button__A6Olm.Button_primary__TKc7J.Button_disabled___OmjN {
    opacity: 0.5;
}

  .Button_button__A6Olm.Button_secondary__oGJkC {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

  .Button_button__A6Olm.Button_secondary__oGJkC.Button_disabled___OmjN {
    opacity: 0.5;
}

  .Button_button__A6Olm.Button_disabled___OmjN {
    cursor: not-allowed;
}

  .Button_button__A6Olm.Button_iconOnly__hkzoj svg {
    margin: 0px;
}

  /* STYLES */

  .Button_button__A6Olm.Button_styleOutline__toLDR {
    border: 2px solid;
  }

.Card_card__tX2Ix {

    display: flex;

    flex-direction: column
}

  .Card_card__tX2Ix .Card_image__edrIj {

    position: relative;

    margin-bottom: 0.5rem;

    height: 0px;

    width: 100%;

    border-radius: 0.25rem;

    padding-top: 64%
}

  /* Aspect ratio box - https://css-tricks.com/aspect-ratio-boxes */

  .Card_card__tX2Ix .Card_image__edrIj img {

    position: absolute;

    top: 0px;

    left: 0px;

    height: 100%;

    width: 100%;

    border-radius: 0.25rem;

    -o-object-fit: cover;

       object-fit: cover
}

  .Card_card__tX2Ix .Card_content__HMpqQ .Card_meta__PvgBe {

    margin-bottom: 0.5rem;

    font-size: 0.875rem;

    line-height: 1.25rem
}

  .Card_card__tX2Ix .Card_content__HMpqQ .Card_title__IijWS {

    margin-bottom: 0.5rem;

    font-weight: 700
}

  .Card_card__tX2Ix .Card_content__HMpqQ .Card_body__eda2c {

    margin-bottom: 0.5rem
}

  .Card_card__tX2Ix .Card_footer__x7j5N {

    margin-top: auto;

    display: flex;

    align-items: center;

    justify-content: space-between
}

  .Card_card__tX2Ix .Card_footer__x7j5N .Card_timestamp__UAog8 {

    font-size: 0.875rem;

    line-height: 1.25rem
}

